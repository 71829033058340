import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const IndexPage = () => {
    return (
        <Grid item align="center" padding={4}>
            <title>Not found | Provenance Page</title>
            <Typography varient="h1" fontWeight="700">
                Sorry
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>
            </Typography>
            <Typography>
                We couldn’t find the Provenance Page that you were looking for.
            </Typography>
        </Grid>
    );
};

export default IndexPage;
